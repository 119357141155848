import React from "react";
import { Link } from "react-router-dom";

const Imprint = () => {
  return (
    <div className="bg-mine">
      <Link to="/">
        <div className="d-flex justify-content-end">
          <button className="btn-smol">
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </Link>

      <div className="impressum">
        <p><strong>Impressum</strong></p>
        <br />
        <p>TenneT TSO GmbH</p>
        <p>Bernecker Straße 70</p>
        <p>95448 Bayreuth</p>
        <p>Deutschland</p>

        <div className="contact-info">
          <p>
            Email: <a href="mailto:info@tennet.eu">info@tennet.eu</a>
          </p>
          <p>Tel: +49 (0) 921 50740-0</p>
          <p>Fax: +49 (0) 921 50740-4095</p>
        </div>

        <br />

        <div className="company-details">
          <p><strong>Vorsitzende des Aufsichtsrats:</strong></p>
          <p>• Manon van Beek</p>
          <p><strong>Geschäftsführung:</strong></p>
          <p>• Maarten Abbenhuis</p>
          <p>• Dr. Arina Freitag</p>
          <p>• Tim Meyerjürgens</p>
          <p>Bayreuth, Amtsgericht Bayreuth: HRB 4923</p>
          <p>Umsatzsteueridentifikationsnummer: DE 815 073 514</p>
          <p>
            <strong>Verantwortlich i.S.d. § 18 Abs. 2 MStV:</strong> Ulrike
            Hörchens, Bernecker Straße 70, 95448 Bayreuth
          </p>
        </div>

        <br />

        <div className="dispute-resolution">
          <p><strong>Hinweis zu Streitbeilegungsverfahren:</strong></p>
          <p>
            Die Europäische Kommission stellt unter{" "}
            <a href="http://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">
              http://ec.europa.eu/consumers/odr
            </a>{" "}
            eine Plattform zur außergerichtlichen Online-Streitbeilegung (sog.
            OS-Plattform) bereit. Wir weisen darauf hin, dass wir weder bereit
            noch verpflichtet sind, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
          </p>
        </div>

        <br />

        <div className="additional-info">
          <p>Im Auftrag von TenneT TSO</p>
          <p>OBCC GmbH & Co. KG und UnityAI Solutions Gbr</p>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
