import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { Spinner, Accordion, Card } from "react-bootstrap";
import { GetMaker, PostMaker } from "../../repository/RequestMaker";
import { Endpoints } from "../../repository/Endpoints";
import { Language } from "./Language";
import useAuth from "../../utils/customHooks/useAuth";

const Courses = () => {
  useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const language = localStorage.getItem("language");

  useEffect(() => {
    localStorage.setItem("lastRoute", "courses");
    window.scrollTo(0, 0);
    GetMaker(
      Endpoints.list_courses +
        localStorage.getItem("tenant_id") +
        "/" +
        localStorage.getItem("language_id")
    ).then((res) => {
      if (res) {
        console.log("courses are ", res.data);
        setCourses(res.data);
        setIsLoading(false);
      }
    });
  }, []);

  useLayoutEffect(() => {
    // Translations("", Language);
  }, []);

  const onNext = (course_id, key, selectedCount) => {
    localStorage.setItem("course_id", course_id);
    localStorage.setItem("checkboxCount", selectedCount);
    console.log("selectedCount is ", selectedCount);

    document.getElementById("confirm" + key).innerHTML = "Loading...";
    PostMaker(
      {
        user_id: localStorage.getItem("user_id"),
        course_id: course_id,
        profile_id: localStorage.getItem("profile_id"),
        course_section: clickedCheckboxes,
      },
      Endpoints.enrollment
    ).then((response) => {
      if (response) {
        localStorage.setItem("enrollment_id", response.data.id);
        GetMaker(Endpoints.list_material + course_id).then((res) => {
          if (res) {
            serialize(res.data);
            localStorage.setItem("lastRoute", "flushed");
            history.push({
              pathname: "/trainer",
            });
          } else {
            document.getElementById("confirm" + key).innerHTML = "Next";
          }
        });
      }
    });
  };

  const serialize = (sections) => {
    var materialsAll = [];
    for (let i = 0; i < sections.length; i++) {
      const materials = sections[i].materials;
      for (let j = 0; j < materials.length; j++) {
        const material = materials[j];
        materialsAll.push(material);
      }
    }
    materialsAll = materialsAll.filter(
      (m) => m.id !== "be9c4a11-57e2-48cf-a6db-1a4d76942878"
    ); // remove specific material
    localStorage.setItem("material", JSON.stringify(materialsAll));
    localStorage.setItem("material_length", materialsAll.length);
    localStorage.setItem("current_material", 0);
  };

  const [selectedCount, setSelectedCount] = useState(0);
  const [clickedCheckboxes, setClickedCheckboxes] = useState([]);

  const onCheckboxSelected = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let count = 0;
    const clickedCheckboxes = [];

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        count++;
        const h4Element = checkbox.parentNode.parentNode.querySelector("h4");
        const name = h4Element.textContent.trim();
        clickedCheckboxes.push(name);
      }
    });

    setSelectedCount(count);
    setClickedCheckboxes(clickedCheckboxes);
    console.log(clickedCheckboxes); // Array containing names of clicked checkboxes
  };

  return (
    <div>
      <div className="bg-mine">
        <h1 className="display-4 text-title-color" id="titlex">
          {Language[language].titlex}
        </h1>

        {isLoading ? (
          <Spinner animation="grow" />
        ) : (
          courses.map((course, key) => {
            // Check if owner.name is Cloppenburg
            const isCloppenburg = course.owner.name === "Cloppenburg";

            return isCloppenburg ? (
              <div key={course.id}>
                {/* <div
                  className="d-flex"
                  style={{
                    padding: 20,
                    borderRadius: 10,
                    alignItems: "center",
                    border: "1px solid #dddeee",
                    marginBottom: "10px",
                  }}
                >
                  <h4 className="text-subtitle-color">{course.name}</h4>
                </div> */}

                {isCloppenburg && (
                  <Accordion defaultActiveKey="1">
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="1"
                        className="d-flex align-items-center"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                  <h4 className="text-subtitle-color">{course.name}</h4>
                  <i className="fa fa-chevron-down"></i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          {["PFA1", "PFA2", "PFA2a", "PFA3", "PFA4"].map(
                            (pfa, index) => (
                              <div
                                className="d-flex"
                                style={{
                                  padding: 10,
                                  borderRadius: 5,
                                  alignItems: "center",
                                  border: "1px solid #dddeee",
                                  marginBottom: "5px",
                                }}
                                key={index}
                              >
                                <h4 className="text-subtitle-color">{pfa}</h4>

                                <label
                                  className="checkbox-container"
                                  style={{ marginLeft: "auto" }}
                                >
                                  <input
                                    type="checkbox"
                                    onChange={() => onCheckboxSelected()}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            )
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}

                <br></br>
                <button
                  type="button"
                  className={`btn-round margin-left-auto ${
                    selectedCount === 0
                      ? "disabled-button"
                      : "enabled-button"
                  }`}
                  onClick={() => onNext(course.id, key, selectedCount)}
                  id={"confirm" + key}
                >
                  {Language[language].button_next}
                  <i className="fa fa-forward icon-border" aria-hidden="true"></i>
                </button>
              </div>
            ) :  <div
                    className="d-flex"
                    style={{
                      padding: 20,
                      borderRadius: 10,
                      alignItems: "center",
                      border: "1px solid #dddeee",
                      marginBottom: "10px",
                    }}
                   >
            
            <h4 className="text-subtitle-color">{course.name}</h4>

            <button
                  type="button"
                  className={"btn-round margin-left-aut0"}
                  onClick={() => onNext(course.id, key)}
                  id={"confirm" + key}
                >
                  {Language[language].button_next}
                  <i className="fa fa-forward icon-border" aria-hidden="true"></i>
                </button>
                
          </div> ;
          })
        )}
      </div>
    </div>
  );
};

export default Courses;
