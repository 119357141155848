import React, { useEffect, useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import { GetMaker } from "../../repository/RequestMaker";
import { Translations } from "../../repository/Translations";
import { Spinner } from "react-bootstrap";
import { Language } from "./Language";
import Modal from "react-modal";
import PrivacyStatement from "../privacyStatement/PrivacyStatement";
import Swal from "sweetalert2";

Modal.setAppElement("#root");
const Home = () => {
  // inits
  const history = useHistory();
  const [languageAvailable, setLanguageAvailable] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [langToggled, setLangToggled] = useState(false);
  const [cookieChecked, setCookieChecked] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [inMaintainance, setInMaintainance] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  // logics
  const onNext = (e) => {
    e.preventDefault();
    if (langToggled && cookieChecked) {
      history.push({
        pathname: "/userdata",
      });
    }
    if (!langToggled) {
      Swal.fire({
        text: "Bitte wählen Sie die gewünschte Sprache // Please select the desired language",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#073e87",
      });
    }
    if (!cookieChecked) {
      Swal.fire({
        text: 'Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen gelesen ...".',
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#073e87",
      });
    }
  };

  const setLanguage = (id) => {
    setLangToggled(true);
    console.log("selected language", id);
    id = id.split(" ");
    localStorage.setItem("language_id", id[0]);
    localStorage.setItem("language", id[1]);
    Translations(id[1], Language);
  };

  useEffect(() => {

    const lastRoute = localStorage.getItem("lastRoute");
    if (lastRoute !== "courses") {
      // localStorage.clear();
    }
    if (lastRoute === "grouptraining") {
      localStorage.setItem("trainingType", "grouptraining");
    }
    window.scrollTo(0, 0);
    localStorage.setItem("dev", "true");

    // localStorage.setItem("tenant_id", "b3363583-9cfe-467a-8304-b056e74872c2");
    // localStorage.setItem("language", "de");
    // localStorage.setItem("language_id", "1");

   const tenantId = localStorage.getItem('tenant_id')
    GetMaker(`${Endpoints.list_languages}?tenant_id=${tenantId}`).then((res) => {
      console.log(res.data);
      setLanguages(res.data);
      setLanguageAvailable(true);
    });
  }, []);

  useLayoutEffect(() => {
    if (!inMaintainance) Translations("de", Language);
  }, []);
  // render
  return inMaintainance ? (
    <h1>Website im Aufbau. In Kürze wieder verfügbar.</h1>
  ) : (
    <>
      <div className="bg-mine">
        <h1 className="display-4 text-title-color" id="title_text">
          Willkommen zu Ihrer
        </h1>
        <h1 className="display-4 text-title-color" id="title_text_2">
          Digitalen Einweisung
        </h1>
        <h1 className="display-4 text-title-color" id="title_text_3">
          Arbeitssicherheit
        </h1>
        <hr className="my-4 text-title-color title_underline" />
        <div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="language_text">
              Sprache
            </span>
            {languageAvailable ? (
              <select
                className="form-control"
                id="language_select"
                onChange={(e) => setLanguage(e.currentTarget.value)}
              >
                <option disabled selected>
                  Bitte wählen Sie die gewünschte Sprache // Please select the
                  desired language
                </option>
                {languages
                  .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
                  .map((data, key) => {
                    return (
                      <option key={key} value={data.id + " " + data.lang}>
                        {data.name}
                      </option>
                    );
                  })}
              </select>
            ) : (
              <Spinner animation="grow" />
            )}
          </div>
        </div>

        <div>
          {/* <div className="d-flex flex-row">
            <div className="d-flex justify-content-start">
              <label className="toggle-control">
                <input
                  type="checkbox"
                  onChange={(e) => setCookieChecked(e.currentTarget.checked)}
                />
                <span className="control"></span>
              </label>
              <span className="margin_left"></span>
              <label
                className="custom-control-label"
                id="privacy_agree_text"
                for="cookieMaker"
                style={{ marginLeft: "30px" }}
              ></label>
              <span
                id="privacy_agree_link"
                // className="btn btn-link"
                style={{
                  padding: 0,
                  // marginTop: -5,
                  marginLeft: 2,
                  marginRight: 2,
                }}
                // onClick={openModal}
              >
                Privacy
              </span>
              <label
                className="custom-control-label"
                id="privacy_agree_text_2"
                for="cookieMaker"
              ></label>
            </div>
          </div>  */}
        </div>
        <div>
          <div className="margin_top"></div>

          <button
            // className="btn btn-outline-dark btn-lg btn-round"
            className="btn-round"
            id="next_button"
            onClick={onNext}
          >
            <span id="button_next"></span> &nbsp;
            <i class="fa fa-forward icon-border" aria-hidden="true"></i>
          </button>
          {/* <p id="next_button_statement" style={{ fontSize: 12 }}>
            Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der
            Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen
            gelesen ...".
          </p> */}
        </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: 20,
          }}
        >
          <div className="d-flex justify-content-end">
            <button className="btn-smol" onClick={closeModal}>
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <PrivacyStatement />
        </Modal>
      </div>
    </>
  );
};

export default Home;
