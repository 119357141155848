import React, { useEffect, useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import { GetMaker } from "../../repository/RequestMaker";
import { Translations } from "../../repository/Translations";
import Modal from "react-modal";
import PrivacyStatement from "../privacyStatement/PrivacyStatement";
import Swal from "sweetalert2";

Modal.setAppElement("#root");

const LandingPage = () => {
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inMaintainance, setInMaintainance] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  // Navigate to Substation Training Page
  const onSubstationTrainingClick = () => {
    console.log("Substation Training button clicked");
    localStorage.setItem("tenant_id", "b3363583-9cfe-467a-8304-b056e74872c2");

    // Verify that the tenant ID has been saved before navigating
    setTimeout(() => {
      const tenantId = localStorage.getItem("tenant_id");
      console.log("TENANT ID ", tenantId);
      if (tenantId) {
        history.push("/home");
      } else {
        console.error("Tenant ID not saved in localStorage");
      }
    }, 100); // Delay for 100ms
  };

  // Navigate to Overhead Line Training Page
  const onOverheadLineTrainingClick = () => {
    console.log("Overhead Line Training button clicked");
    localStorage.setItem("tenant_id", "d86ab1e4-5c7d-4241-9fe0-f2a6088cf16d");

    // Delay navigation slightly to ensure localStorage is updated
    setTimeout(() => {
      const tenantId = localStorage.getItem("tenant_id");
      if (tenantId) {
        history.push("/home");
      } else {
        console.error("Tenant ID not saved in localStorage");
      }
    }, 100); // Delay for 100ms
  };

  useEffect(() => {

    const lastRoute = localStorage.getItem("lastRoute");
    if (lastRoute !== "courses") {
      localStorage.clear();
    }
    if (lastRoute === "grouptraining") {
      localStorage.setItem("trainingType", "grouptraining");
    }
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    if (!inMaintainance) Translations("de", {});
  }, []);

  return inMaintainance ? (
    <h1>Website im Aufbau. In Kürze wieder verfügbar.</h1>
  ) : (
    <>
      <div className="bg-mine">
        <h1 className="display-4 text-title-color" id="title_text">
          Willkommen auf dem TenneT-Schulungsportal für das Projekt A240!
        </h1>
        <p>
          Wir freuen uns Sie hier begrüßen zu dürfen. In den weiteren Schritten
          haben Sie die Möglichkeit, die für Ihre Tätigkeit relevanten
          Schulungen zu absolvieren.
        </p>
        <p>
          Nach erfolgreicher Absolvierung der Schulungen und dem dazugehörigen
          Test erhalten Sie am Ende ein Zertifikat.
        </p>
        <p>
          Für die Schulungen für die <b>Freileitungen</b> in den{" "}
          <b> Umspannwerken/ Punktbauwerke (Garrel, Cappeln)</b> wählen Sie
          bitte <b>die linke Option,</b> und für die{" "}
          <b>Schulungen, im Freileitungsbau</b> klicken Sie bitte auf den{" "}
          <b>rechten Button.</b>
        </p>
        <p>
          Wir danken Ihnen für Ihre Teilnahme und wünschen Ihnen viel Erfolg.
        </p>
        <hr className="my-4 text-title-color title_underline" />

        <div className="row d-flex justify-content-between">
        <div className="col-md-4 d-flex justify-content-start">

          <div className="container_button">
            <button
              className="btn-intern-extern"
              id="next_button"
              onClick={onSubstationTrainingClick}
            >
              <span id="button_intern"> Umspannwerk/Punktbauwerke</span>
            </button>
          </div>
</div>
          {/* <br></br>
          <br></br> */}
          <div className="col-md-4 d-flex justify-content-end">
            <div className="container_button">
              <button
                className="btn-intern-extern"
                id="next_button"
                onClick={onOverheadLineTrainingClick}
              >
                <span id="button_intern">Freileitung</span>
              </button>
            </div>
          </div>
          {/* <div className="row">

          <div className="col-md-4">
  <button
    className="btn-round"
    id="substationTrainingButton"
    style={{
      fontSize: "1.2rem",
      fontWeight: "bold",
      maxWidth: "100%", 
      padding: "10px 20px", // Proper padding
      whiteSpace: "normal", // Allow text to wrap
      wordWrap: "break-word", // Break long words if needed
      textAlign: "center", // Center the text
    }}
    onClick={onSubstationTrainingClick}
  >
    Umspannwerk/Punktbauwerke
  </button>
</div>
<div className="col-md-4">
  <button
    className="btn-round"
    id="overheadLineTrainingButton"
    style={{
      fontSize: "1.2rem",
      fontWeight: "bold",
      maxWidth: "100%", // Keep buttons in sync
      padding: "10px 20px",
      whiteSpace: "normal",
      wordWrap: "break-word",
      textAlign: "center",
    }}
    onClick={onOverheadLineTrainingClick}
  >
    Freileitung
  </button>
</div>

           
          </div> */}
        </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: 20,
          }}
        >
          <div className="d-flex justify-content-end">
            <button className="btn-smol" onClick={closeModal}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <PrivacyStatement />
        </Modal>
      </div>
    </>
  );
};

export default LandingPage;
