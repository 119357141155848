import axios from "axios";
import { Endpoints } from "./Endpoints";
const api = Endpoints.api;
export function PostMaker(data, path) {
  console.log(api + path);
  return axios
    .post(api + path, data, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch((err) => {
      if (err.response) {
        alert(err.response.data.message);
        return;
      }
    });
}

export function GetMaker(path) {
  const fullpath = api + path;
  console.log(fullpath);
  return axios
    .get(fullpath, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
    .catch((err) => {
      if (err.response) {
        alert(err.response.data.message);
        return;
      }
    });
}



export async function StreamMaker(path) {
  const fullpath = api + path;
  const result = await fetch(fullpath, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
  const blob = await result.blob();
  if (blob) {
    return URL.createObjectURL(blob);
  } else {
    console.warn("Can not load");
    return;
  }
}

export function SubtitleStreamMaker(path) {
  const fullpath = api + path;
  console.log(fullpath);
  return axios(fullpath, {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => {
      const file = new Blob([response.data], {
        type: "text/vtt",
      });
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function PDFStreamMaker(path) {
  const fullpath = api + path;
  console.log(fullpath);
  return axios(fullpath, {
    method: "GET",
    responseType: "blob", //Force to receive data in a Blob Format
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => {
      const file = new Blob([response.data], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    })
    .catch((error) => {
      console.log(error);
    });
}
