import React from "react";

const PrivacyDE = () => {
  return (
    <>
      <div class="section">
        <h3>I. Kontaktdaten des Verantwortlichen</h3>
        <p>
          Verantwortlicher ist die Stelle, die allein -oder gemeinsam mit anderen- über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist die:
        </p>
        <br />
        <div class="address">
          <p>OBCC Online Business und Community Communication GmbH & Co. KG</p>
          <p>Vertreten durch: Walter Lorz</p>
          <p>Frankfurter Straße 8</p>
          <p>36043 Fulda</p>
          <p>Deutschland</p>
          <p>Tel.: +49 (0) 661 / 4109 41 - 90</p>
          <p>
            E-Mail: <a href="mailto:info@obcc.de">info@obcc.de</a>
          </p>
          <p>
            Website: <a target="_blank" href="https://www.obcc.de/">www.obcc.de</a>
          </p>
        </div>

        <h3>II. Kontaktdaten des Datenschutzbeauftragten</h3>
        <p>Den Datenschutzbeauftragten des Verantwortlichen erreichen Sie wie folgt:</p>
        <div class="address">
          <p>BerIsDa GmbH</p>
          <p>Rangstraße 9</p>
          <p>36037 Fulda</p>
          <p>Deutschland</p>
          <p>Tel.: +49 661 29 69 80 90</p>
          <p>E-Mail: <a href="mailto:datenschutz@berisda.de">datenschutz@berisda.de</a></p>
          <p>
            Website: <a target="_blank" href="https://berisda.de/">www.berisda.de</a>
          </p>
        </div>

        <h3>III. Beschreibung der Verarbeitung</h3>
        <h5>1. Zweck der Verarbeitung</h5>
        <p>Die Verarbeitung dient der Organisation, Vorbereitung (inkl. Einladung), Durchführung und dem Nachweis von Einweisungen.</p>

        <h5>2. Rechtsgrundlage der Verarbeitung</h5>
        <ul>
          <h6>a) Aufgrund einer Einwilligung (gem. Art. 6 Abs. 1 lit. a DSGVO)</h6>
          <p>Die Zwecke der Verarbeitung personenbezogener Daten ergeben sich aus der Erteilung einer Einwilligung...</p>

          <h6>b) Zur Vertragsanbahnung, Vertragsdurchführung und Beendigung von Vertragsverhältnissen (gem. Art. 6 Abs. 1 lit. b DSGVO)</h6>
          <p>Die Zwecke der Datenverarbeitung ergeben sich zum einen aus der Einleitung vorvertraglicher Maßnahmen...</p>

          <h6>c) Aufgrund gesetzlicher Vorgaben (gem. Art. 6 Abs.1 lit. c DSGVO) oder im öffentlichen Interesse (gem. Art. 6 Abs.1 lit. e DSGVO)</h6>
          <p>Die Zwecke der Datenverarbeitung ergeben sich aus gesetzlichen Vorgaben...</p>

          <h6>d) Aufgrund eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO)</h6>
          <p>Die Zwecke der Verarbeitung ergeben sich aus der Wahrung unserer berechtigten Interessen...</p>
        </ul>

        <h5>3. Umfang der Datenverarbeitung</h5>
        <p>
          Für die vorgenannten Zwecke verarbeitet die OBCC GmbH & Co. KG gegebenenfalls die folgenden Kategorien personenbezogener Daten:
        </p>
        <ul>
          <li>a. Angaben zum Teilnehmer Stammdaten (Vor- und Nachname, Geburtstag); zugehörige Firma, E-Mail-Adresse</li>
          <li>b. Schulungs- und Teilnahmedaten</li>
          <li>c. Logfiles, Protokolldaten, Metadaten (z.B. IP-Adresse, Zeitpunkt des Zugriffs, etc.)</li>
        </ul>

        <h5>4. Datenlöschung und Speicherdauer</h5>
        <p>
          Die Verarbeitung der von Ihnen überlassenen Daten erfolgt so lange, wie sie zur Erreichung des vertraglich vereinbarten Zweckes notwendig ist...
        </p>

        <h5>5. Empfänger der Daten</h5>
        <p>
          Es haben nur Mitarbeiter Zugriff auf Ihre Daten, die für das Thema Einweisungen-Online zuständig sind, sowie die Geschäftsführung und der Datenschutzbeauftragte...
        </p>

        <h5>6. Datenübermittlung an Drittländer</h5>
        <p>Eine Übermittlung der von Ihnen überlassenen Daten an ein Drittland oder eine internationale Organisation erfolgt in keinem Fall...</p>

        <h3>IV. Auskunft über Ihre Rechte:</h3>
        <h5>1. Auskunftsrecht</h5>
        <p>
          Sie haben das Recht, jederzeit im Umfang von Art. 15 DSGVO Auskunft über Ihre personenbezogenen Daten zu verlangen, die wir verarbeiten...
        </p>

        <h5>2. Recht auf Berichtigung und Ergänzung:</h5>
        <p>Sollten Ihre personenbezogenen Daten unrichtig oder unvollständig sein,  haben Sie im Umfang von Art. 16 DSGVO ein Recht auf Berichtigung und
            Ergänzung.
            <br /> </p>
            <h5>3. Recht auf Einschränkung der Verarbeitung</h5>
            Bei Vorliegen der gesetzlichen Voraussetzungen können Sie im Umfang von
            Art. 18 DSGVO eine Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten verlangen.
            <br />
            <h5>4. Recht auf Löschung</h5>
            Sie können im Umfang von Art. 17 DSGVO jederzeit die Löschung Ihrer
            personenbezogenen Daten verlangen, sofern wir nicht rechtlich zur weiteren
            Verarbeitung Ihrer Daten verpflichtet oder berechtigt sind.
            <br />
            <h5>5. Recht auf Datenübertragbarkeit</h5>
            Erfolgt eine Verarbeitung auf Grundlage Ihrer Einwilligung und mithilfe
            automatisierter Verfahren, so haben Sie im Umfang von Art. 20 DSGVO ein
            Recht auf Übertragung der von Ihnen bereitgestellten Daten, sofern dadurch
            nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
            <br />
            <h5>6. Widerspruchsrecht</h5>
            Sie haben das Recht, im Umfang von Art. 21 DSGVO gegen eine Verarbeitung
            Widerspruch zu erheben, soweit die Datenverarbeitung zum Zwecke der
            Direktwerbung oder des Profilings erfolgt. Einer Verarbeitung auf Grund
            einer Interessenabwägung können Sie unter Angabe von Gründen, die sich aus
            Ihrer besonderen Situation ergeben, widersprechen.
            <br />
            <h5>
              7. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
            </h5>
            Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
            jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
            Verarbeitung nicht berührt.
            <br />
            <h5>8. Recht auf Beschwerde bei einer Aufsichtsbehörde</h5>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
            Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts,
            ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn
            Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten gegen die DSGVO verstößt.
            <br />
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
            unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der
            Beschwerde einschließlich der Möglichkeit eines gerichtlichen
            Rechtsbehelfs nach Art. 78 DSGVO.
            <div style={{ height: 50 }}></div>
      </div>
    </>
  );
};

export default PrivacyDE;
